var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "", md12: "" } },
    [
      _c(
        "v-tabs",
        {
          attrs: { "slider-color": "secondary" },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _vm._l(_vm.organizations, function(organization, index) {
            return [
              _c(
                "v-tab",
                {
                  key: "planning-tab-" + index,
                  staticClass: "tab__filled",
                  class: { active: _vm.activeTab === organization.id },
                  attrs: { href: "#" + organization.id },
                  on: {
                    click: function($event) {
                      return _vm.getReports(organization.id)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(organization.name) + " ")]
              )
            ]
          }),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _vm._l(_vm.organizations, function(organization) {
                return [
                  _c(
                    "v-tab-item",
                    {
                      key: "planning-tab-content-" + organization.name,
                      attrs: {
                        lazy: true,
                        transition: false,
                        "reverse-transition": false,
                        value: organization.id
                      }
                    },
                    [
                      _vm.activeTab === organization.id
                        ? _c(
                            "div",
                            {
                              staticClass: "elementPanel",
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-end": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "text-right": "" } },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          attrs: {
                                            color: "secondary",
                                            small: "true",
                                            icon: "cloud_download",
                                            disabled: _vm.isCreatingExcel
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.downloadFileBlob(
                                                organization.id,
                                                true
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Excel export aanmaken ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm4: "" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "dialog",
                                          refInFor: true,
                                          attrs: {
                                            "close-on-content-click": false,
                                            lazy: "",
                                            "nudge-right": "130",
                                            "full-width": "",
                                            "max-width": "290px",
                                            "min-width": "290px"
                                          },
                                          model: {
                                            value: _vm.isEditingDate,
                                            callback: function($$v) {
                                              _vm.isEditingDate = $$v
                                            },
                                            expression: "isEditingDate"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "input input-date",
                                            attrs: {
                                              slot: "activator",
                                              "hide-details": "",
                                              placeholder: "dd-mm-jjjj",
                                              label: "Datum",
                                              "error-messages":
                                                _vm.dateErrorMessage
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.formatdateFromTextField(
                                                  $event
                                                )
                                              }
                                            },
                                            slot: "activator",
                                            model: {
                                              value: _vm.dateFormatted,
                                              callback: function($$v) {
                                                _vm.dateFormatted = $$v
                                              },
                                              expression: "dateFormatted"
                                            }
                                          }),
                                          _c("v-date-picker", {
                                            ref: "picker",
                                            refInFor: true,
                                            attrs: {
                                              locale: "nl-nl",
                                              min: "1910-01-01",
                                              color: "#837f16",
                                              "first-day-of-week": "1"
                                            },
                                            on: {
                                              change: function($event) {
                                                _vm.isEditingDate = false
                                                _vm.getReports(_vm.activeTab)
                                              }
                                            },
                                            model: {
                                              value: _vm.date,
                                              callback: function($$v) {
                                                _vm.date = $$v
                                              },
                                              expression: "date"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm4: "" } },
                                    [
                                      _c("ReportTypeFilter", {
                                        attrs: { cssClass: "with-border" },
                                        on: {
                                          handleChange: function($event) {
                                            return _vm.getReports(_vm.activeTab)
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedReportTypes,
                                          callback: function($$v) {
                                            _vm.selectedReportTypes = $$v
                                          },
                                          expression: "selectedReportTypes"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm4: "" } },
                                    [
                                      _c("ReportSkillFilter", {
                                        attrs: { cssClass: "with-border" },
                                        on: {
                                          handleChange: function($event) {
                                            return _vm.getReports(_vm.activeTab)
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedSkills,
                                          callback: function($$v) {
                                            _vm.selectedSkills = $$v
                                          },
                                          expression: "selectedSkills"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm4: "" } },
                                    [
                                      _c("ReportDepartmentFilter", {
                                        attrs: {
                                          dmz: _vm.activeTab,
                                          cssClass: "with-border"
                                        },
                                        on: {
                                          handleChange: function($event) {
                                            return _vm.getReports(_vm.activeTab)
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedDepartments,
                                          callback: function($$v) {
                                            _vm.selectedDepartments = $$v
                                          },
                                          expression: "selectedDepartments"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm4: "" } },
                                    [
                                      _c("ReportExpertFilter", {
                                        attrs: {
                                          dmz: _vm.activeTab,
                                          cssClass: "with-border",
                                          placeholder:
                                            "" +
                                            (_vm.imgUuid === _vm.activeTab
                                              ? "Zaakbegeleider"
                                              : "Opnemer"),
                                          label:
                                            "" +
                                            (_vm.imgUuid === _vm.activeTab
                                              ? "Zaakbegeleider"
                                              : "Opnemer")
                                        },
                                        on: {
                                          handleChange: function($event) {
                                            return _vm.getReports(_vm.activeTab)
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedExperts,
                                          callback: function($$v) {
                                            _vm.selectedExperts = $$v
                                          },
                                          expression: "selectedExperts"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm4: "" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: "Afspraken valideren",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.validateEvents,
                                          callback: function($$v) {
                                            _vm.validateEvents = $$v
                                          },
                                          expression: "validateEvents"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.isLoading
                                ? _c("LoaderCard", {
                                    attrs: { flat: "", type: "spinner--center" }
                                  })
                                : _vm._e(),
                              !_vm.isLoading
                                ? _vm._l(_vm.planningData, function(
                                    planning,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: "day-" + index,
                                        staticClass: "overflow-auto"
                                      },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "item__list item__list--table my-4"
                                          },
                                          [
                                            _c("thead", [
                                              _c(
                                                "tr",
                                                {
                                                  staticClass:
                                                    "label header__row--bordered",
                                                  attrs: {
                                                    wrap: "",
                                                    row: "",
                                                    "align-center": "",
                                                    rowspan: "6"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "td",
                                                    { attrs: { colspan: "8" } },
                                                    [
                                                      _c("h3", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDate"
                                                            )(planning.name)
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "tr",
                                                {
                                                  staticClass:
                                                    "label header__row",
                                                  attrs: {
                                                    wrap: "",
                                                    row: "",
                                                    "align-center": ""
                                                  }
                                                },
                                                [
                                                  _c("td", [_vm._v("Tijd")]),
                                                  _c("td", [
                                                    _vm._v("Dossiernummer")
                                                  ]),
                                                  _c("td", [
                                                    _vm._v("Opnamevariant")
                                                  ]),
                                                  _c("td", [
                                                    _vm._v("Rapporttype")
                                                  ]),
                                                  _c("td", [_vm._v("Adres")]),
                                                  _vm.validateEvents
                                                    ? _c("td")
                                                    : _vm._e(),
                                                  _vm.imgUuid !== _vm.activeTab
                                                    ? _c("td", [
                                                        _vm._v("Schade-expert")
                                                      ])
                                                    : _vm._e(),
                                                  _c("td", [
                                                    _vm._v("Zaakbegeleider")
                                                  ]),
                                                  _c("td", [
                                                    _vm._v("Afspraaktype")
                                                  ]),
                                                  _c("td", [
                                                    _vm._v("Competenties")
                                                  ])
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(planning.data, function(
                                                report,
                                                index
                                              ) {
                                                return _c(
                                                  "tr",
                                                  {
                                                    key: "day-" + index,
                                                    staticClass: "list__item"
                                                  },
                                                  [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("formatTime")(
                                                            report.starts_at
                                                          )
                                                        ) +
                                                          " - " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatTime"
                                                            )(report.ends_at)
                                                          )
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href:
                                                              "/reports/" +
                                                              report.id +
                                                              "/planning",
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              report.case_number
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .opnameVariantLabels[
                                                              report
                                                                .opname_variant
                                                            ]
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            report.report_type
                                                          ) +
                                                          " "
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                report.address
                                                                  .street
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                report.address
                                                                  .number
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                report.address
                                                                  .number_add
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                report.address
                                                                  .postcode
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                report.address
                                                                  .city
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _vm.validateEvents
                                                      ? _c("td", [
                                                          _c("span", {
                                                            class:
                                                              "status-dot " +
                                                              (report.is_valid
                                                                ? "success"
                                                                : "warning")
                                                          })
                                                        ])
                                                      : _vm._e(),
                                                    _vm.imgUuid !==
                                                    _vm.activeTab
                                                      ? _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              report.expert
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          report.case_mediator
                                                        )
                                                      )
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(report.type)
                                                      )
                                                    ]),
                                                    _c(
                                                      "td",
                                                      _vm._l(
                                                        report.skills,
                                                        function(skill, index) {
                                                          return _c(
                                                            "v-chip",
                                                            {
                                                              key:
                                                                "skill-" + index
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    skill.name
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ]
                                                )
                                              }),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                ]
              })
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }