import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Organization, organizationUuid } from '@/models/Organization';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Settings } from 'luxon';
import { setFormattedDatePickerValue, isValidDate, dateErrorMessage, formatDate } from '@/support/String';
import Client, { downloadExcel } from '@/support/Client';
import { Setting } from '@/models/Setting';
import { cloneDeep } from 'lodash';
import { ReportType } from '@/models/ReportType';
import { Skill } from '@/models/Skill';
import { Department } from '@/models/Department';
import { Project } from '@/models/Project';
import { OpnameVariantLabels } from '@/items/OpnameVariant';
import { Export } from '@/models/Export';
import OpnameVariantFilter from '../filters/opname-variant-filter/OpnameVariantFilter';

@Component<WeekPlanningComponent>({
  filters: {
    formatTime: (date: string) => {
      if (! date) {
        return '';
      }

      return formatDate(date, 'HH:mm');
    },
    formatDate: (date: string) => {
      if (! date) {
        return '-';
      }

      return formatDate(date, 'cccc dd-LL-yyyy');
    },
  },
})
export default class WeekPlanningComponent extends Vue {
  @Prop()
  protected items!: any;

  @Prop()
  protected project!: Project;

  protected organizations: Organization[] | null = null;

  protected isCreatingExcel = false;

  protected isEditingDate = false;

  protected isLoading = true;

  protected date = '';

  protected validateEvents = false;

  protected activeTab = '';

  protected planningData: any[] = [];

  protected reportTypes: ReportType[] = [];

  protected selectedReportTypes: ReportType[] = [];

  protected selectedExperts: string[] = [];

  protected skills: Skill[] = [];

  protected selectedSkills: Skill[] = [];

  // departments
  protected departments: Department[] = [];

  protected selectedDepartments: string[] = [];

  protected async mounted() {
    Settings.defaultLocale = 'nl';
    await this.getDepartments();
    await this.getReportTypes();
    await this.getSkills();
    await this.getSettings();
  }

  protected async getDepartments() {
    this.departments = await new Department()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getSkills() {
    await new Skill()
      .all()
      .then((skills: Skill[]) => {
        this.skills = skills;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getReportTypes() {
    await new ReportType()
      .all()
      .then((reportTypes: ReportType[]) => {
        this.reportTypes = reportTypes;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected async getSettings() {
    await new Setting()
      .all()
      .then((settings: Setting[]) => {
        const start = settings.find((currentSetting: Setting) => currentSetting.key === 'planning_active_week');

        if (! start || ! start.value) {
          return;
        }

        this.date = formatDate(start.value as string, 'yyyy-LL-dd');
        if (this.project && this.project.organization) {
          this.organizations = [cloneDeep(this.project.organization)];
          this.activeTab = this.organizations[0].id as string;
          this.getReports(this.organizations[0].id as string);
        } else if (this.$store.state.isServiceOrganization) {
          this.getOrganizations();
        } else {
          this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
          this.activeTab = this.organizations[0].id as string;
          this.getReports(this.organizations[0].id as string);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getOrganizations() {
    new Organization()
      .all()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;

        if (organizations && organizations.length) {
          this.activeTab = organizations[0].id as string;
          this.getReports(organizations[0].id as string);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getReports(id: string) {
    if (! id) {
      return;
    }

    this.isLoading = true;

    const payload = {
      date: this.date,
      types: this.selectedReportTypes,
      skills: this.selectedSkills,
      departments: this.selectedDepartments,
      experts: this.selectedExperts.length > 0 ? this.selectedExperts : null,
      project: this.project?.id,
    };

    Client('get', '/dmz/planning/week-planning', payload, false, id)
      .then((response: AxiosResponse) => {
        this.planningData = [];
        this.planningData = response.data;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async downloadFileBlob(id: string) {
    this.isCreatingExcel = true;

    const payload = {
      date: this.date,
      organization: id,
      filters: {
        types: this.selectedReportTypes,
        skills: this.selectedSkills,
        departments: this.selectedDepartments,
        experts: this.selectedExperts.length > 0 ? this.selectedExperts : null,
        project: this.project?.id,
      },
    };

    await new Export().downloadWeekPlanningExcel(payload);

    this.isCreatingExcel = false;
  }

  protected get opnameVariantLabels(): {[key: string] :string} {
    return OpnameVariantLabels;
  }

  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected formatDateDatePicker() {
    if (this.date) {
      this.dateFormatted = setFormattedDatePickerValue(this.date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatDateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.date = setFormattedDatePickerValue(value);
  }

  protected get imgUuid() {
    return organizationUuid.img;
  }

  @Watch('date')
  protected DateChanged() {
    this.formatDateDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingFromChanged() {
    if (! this.isEditingDate) {
      this.formatDateDatePicker();
    }
  }
}
